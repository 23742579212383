import { type Component, Show, Switch, Match, createMemo } from "solid-js";
import { useNavigate } from "@solidjs/router";
import { Icon } from "solid-heroicons";
import { PT, PTLabels, StackPTLabels } from "~/utils/products";
import { exclamationCircle } from "solid-heroicons/outline";
import { capitalize } from "~/utils/helpers";
import { type CartDetail } from "~/services/roma-api/cart/types";
import { type FormattedCart } from "~/routes/(layout)/checkout/[cart_id]";
import {
  StackLineItem,
  ChopLineItem,
  JoinLineItem,
  LengthLineItem,
  MirrorLineItem,
  GenericProductLineItem,
} from "~/services/roma-api/cart/types-lineItems";
import {
  AllowanceLabels,
  AllowanceLabelsAbbr,
  type AllowanceOption,
  OrientationLabels,
  type OrientationOption,
  WedgeLabels,
  type WedgeOption,
  BevelLabels,
  type BevelOption,
  CuttingLabels,
} from "~/services/roma-api/cart/types-productOrdering";
import { cuttingInstrOptions } from "../order-page/product-types";
import { frameDictionary } from "~/utils/builtFrames";
import { facilityMapping } from "../order-page";
import { OrderLineItem } from "~/services/roma-api/admin/orders";

// !TODO REFACTOR
// This comp and LineDetails are very similar, require same typeguard checks, render similar data onyl slightly differently
// This was a quick copy/light type cleanup for 2.0

export const formatCustomCuts = (cuts: number[] | undefined) => {
  if (!cuts || !Array.isArray(cuts)) return "";

  const str = cuts.map((cut) => (!!cut ? cut + '"' : "balance")).join(", ");
  return "Cut @ " + str;
};

export const LineAttributes: Component<{
  line:
    | CartDetail["Lines"][number]
    | FormattedCart["Lines"][number]
    | OrderLineItem;
  rootClass?: string;
  textSize?: "xs";
  truncate?: boolean;
  hideShipAttr?: boolean;
}> = (props) => {
  const item = () => props.line.SubItems[0];
  const navigate = useNavigate();

  const textSize = () => (props.textSize === "xs" ? "text-xs" : "text-sm");

  // For stacks, check if *any of the layers have a wedge
  const stackWedge = createMemo(() => {
    if (props.line.Type == "stack") {
      for (const x of props.line.SubItems) {
        if (x.Wedge) {
          return true;
        }
      }
    }
    return false;
  });

  const Dimensions = () => {
    return (
      <>
        <Show
          when={props.line.Type == "stack"}
          fallback={
            <>
              <span class="fraction font-normal">
                {item().Width} {item().WidthFraction ?? ""}"
              </span>
              <span class={`text-xs  mx-1"`}>&#10005;</span>
              <span class="fraction font-normal">
                {item().Length} {item().LengthFraction ?? ""}"
              </span>
              <span>({item().Layer == "O" ? "Outside" : "Inside"})</span>
            </>
          }
        >
          <span class="fraction font-normal">
            {(props.line as StackLineItem).FrameSize?.Width}{" "}
            {(props.line as StackLineItem).FrameSize?.WidthFraction ?? ""}"
          </span>
          <span class={`text-xs  mx-1`}>&#10005;</span>
          <span class="fraction font-normal">
            {(props.line as StackLineItem).FrameSize?.Length}{" "}
            {(props.line as StackLineItem).FrameSize?.LengthFraction ?? ""}"
          </span>
          <span>
            (
            {(props.line as StackLineItem).FrameSize?.Sizing == "outside"
              ? "Outside"
              : "Inside"}
            )
          </span>
        </Show>
      </>
    );
  };

  return (
    <>
      <div
        class="ml-4 self-start flex flex-col gap-0 h-full"
        classList={{ [`${props.rootClass}`]: !!props.rootClass }}
      >
        <Switch
          fallback={
            <>
              <p
                class={`${
                  props.textSize === "xs" ? "text-sm" : "text-base"
                } font-bold text-gray-900 flex items-center`}
              >
                {item()?.Moulding}
                <Show when={props.line.Tag}>
                  <span class={`ml-2 ${textSize()} text-roma-medium-grey`}>
                    {props.line.Tag}
                  </span>
                </Show>
              </p>
              <p class={`${textSize()} font-light text-roma-dark-grey`}>
                <span class="font-light">
                  {props.line.Quantity}
                  <span class="text-xs mx-1">&#10005;</span>
                </span>
                {PTLabels[props.line.Type]}
              </p>
            </>
          }
        >
          <Match when={props.line.Type === "stack"}>
            <>
              <p class={`${textSize()} font-bold text-gray-900`}>
                {(props.line as StackLineItem).FrameSize?.Sizing == "inside"
                  ? [...props.line.SubItems]
                      .reverse()
                      .map((item) => item?.Moulding)
                      .join(" / ")
                  : props.line.SubItems.map((item) => item.Moulding).join(
                      " / "
                    )}
                <Show when={props.line.Tag}>
                  <span class={`ml-2 ${textSize()} text-roma-medium-grey`}>
                    {props.line.Tag}
                  </span>
                </Show>
              </p>
              <p class={`${textSize()} font-light text-roma-dark-grey`}>
                <span class="font-light">
                  {props.line.Quantity}
                  <span class="text-xs mx-1">&#10005;</span>
                </span>
                {
                  StackPTLabels[
                    props.line.Type +
                      capitalize((props.line as StackLineItem).Operation) +
                      (props.line as StackLineItem).SubItems.length
                  ]
                }
              </p>
            </>
          </Match>
          {/* THIS IS FOR CUSTOM ITEMS ? USED IN ORDER APPROVAL PAGE */}
          <Match when={props.line.Type === PT.PRODUCT}>
            <>
              <p
                class={`${
                  props.textSize === "xs" ? "text-sm" : "text-base"
                }  font-bold text-gray-900 flex items-center`}
              >
                {item()?.SKU}
                <Show when={props.line.Tag}>
                  <span class={`ml-2 ${textSize()} text-roma-medium-grey`}>
                    {props.line.Tag}
                  </span>
                </Show>
              </p>
              <p class={`${textSize()} font-light text-roma-dark-grey`}>
                <span class="font-light">
                  <span class="text-xs mx-1">&#10005;</span>
                  {props.line.Quantity}
                </span>
              </p>
            </>
          </Match>
        </Switch>

        <div class={`${textSize()} font-light text-roma-dark-grey`}>
          {/* <span class="font-normal">
            {line.Quantity}<span class="text-xs mx-1">&#10005;</span>
          </span> */}
          <Switch>
            <Match
              when={[PT.CHOP, PT.JOIN, PT.MIRROR, PT.STACK].includes(
                props.line.Type as PT
              )}
            >
              <Dimensions />
              <Show when={(props.line as CJS).Allowance}>
                <span>
                  , Allowance:{" "}
                  {AllowanceLabelsAbbr[(props.line as CJS).Allowance!]}
                </span>
              </Show>
              <Show when={props.line.SubItems[0].Orientation}>
                <span>
                  , Orientation: {/* @ts-expect-error */}
                  {OrientationLabels[props.line.SubItems[0].Orientation]}
                </span>
              </Show>
              <Show when={item().Wedge && props.line.Type !== PT.STACK}>
                <span>, {WedgeLabels[item().Wedge as WedgeOption]}</span>
              </Show>
              <Show when={props.line.Type == PT.STACK && stackWedge() == true}>
                <span>, Wedged</span>
              </Show>
              <Show when={(props.line as MirrorLineItem).Bevel}>
                <span>, {(props.line as MirrorLineItem).Bevel}</span>,
              </Show>
            </Match>
            <Match when={props.line.Type == PT.RAIL}>
              <span class="fraction font-light">
                {item().Length} {item().LengthFraction ?? ""}"
              </span>
              <span class="font-light">
                ({item().Layer == "O" ? "Outside" : "Inside"})
              </span>
              <Show when={props.line.SubItems[0].Orientation}>
                <span>
                  , Orientation: {/* @ts-expect-error */}
                  {capitalize(props.line.SubItems[0].Orientation)}
                </span>
              </Show>
            </Match>
            <Match
              when={[PT.LENGTH, PT.CONTRACT].includes(props.line.Type as PT)}
            >
              <span class="fraction font-light">
                {item().Length} {item().LengthFraction ?? ""}
                {props.line.Type == PT.RAIL ? `"` : "ft"}
              </span>{" "}
              {/* <Show when={(props.line as LengthLineItem).Cutting} 
              fallback={
                <span>
                  {(props.line as LengthLineItem).Cutting.substring(
                    0,
                    15
                  ) + "..."}
                </span>
              }
              > */}
              <Show when={(props.line as LengthLineItem).Cutting}>
                {" "}
                (
                <Switch
                  fallback={
                    <span title={(props.line as LengthLineItem).Cutting}>
                      {(props.line as LengthLineItem).Cutting.substring(0, 15) +
                        "..."}
                    </span>
                  }
                >
                  <Match
                    when={(props.line as LengthLineItem).Cutting === "custom"}
                  >
                    <span>
                      {formatCustomCuts(
                        (props.line as LengthLineItem).CuttingPositions
                      )}
                    </span>
                  </Match>
                  <Match
                    when={cuttingInstrOptions
                      .slice(0, -1)
                      .map((item) => item.value)
                      .includes((props.line as LengthLineItem).Cutting)}
                  >
                    <span>
                      {CuttingLabels[(props.line as LengthLineItem).Cutting]}
                    </span>
                  </Match>
                </Switch>
                )
              </Show>
              {/* </Show> */}
            </Match>
            <Match when={props.line.Type == PT.BOX}>
              <p class="">Box of {props.line.CalculatedQuantity} ft.</p>
            </Match>
            <Match when={props.line.Type == PT.GALLERYFRAME}>
              <span>
                {
                  frameDictionary[item().SKU?.split("-")[1].split("M")[0]]
                    ?.width
                }
                " <span class="text-xs mx-px">&#10005;</span>{" "}
                {
                  frameDictionary[item().SKU?.split("-")[1].split("M")[0]]
                    ?.height
                }
                "{" "}
              </span>
            </Match>
            <Match when={props.line.Type == PT.PHOTOFRAME}>
              <span>
                {frameDictionary[item().SKU?.split("-")[1]]?.width}"{" "}
                <span class="text-xs mx-px">&#10005;</span>{" "}
                {frameDictionary[item().SKU?.split("-")[1]]?.height}"{" "}
              </span>
            </Match>
            {/* TODO  */}
            <Match when={props.line.Type === PT.PRODUCT}>
              <Show when={(props.line as GenericProductLineItem).AssetID}>
                <span class="italic">Print asset provided</span>
              </Show>
              <Show when={(props.line as GenericProductLineItem).AssetBleed}>
                <p class="italic">
                  Bleed: {(props.line as GenericProductLineItem).AssetBleed}
                </p>
              </Show>
            </Match>
          </Switch>
          <Show when={!props.hideShipAttr}>
            <Show when={props.line.BackOrder}>
              <p class={`text-orange-400 ${textSize()}`}>Place on Backorder</p>
            </Show>
            <Show when={props.line.CancelBackOrder}>
              <p class={`text-orange-400 ${textSize()}`}>Cancel on Backorder</p>
            </Show>
            <Show when={props.line.Plant && props.line.Plant !== ""}>
              <p>Ships From: {facilityMapping[props.line?.Plant]} </p>
            </Show>
            <Show when={props.line.FreightAccepted === true}>
              <p class={`text-orange-400 ${textSize()}`}>
                Additional Freight Charge
              </p>
            </Show>
          </Show>
          <Show
            when={
              !!(props.line as FormattedCart["Lines"][number])
                ?.RequiresBackorderSelection
            }
          >
            <button
              class={`text-roma-blue ${textSize()} self-start flex items-center`}
              onClick={() => {
                navigate(`/order?editCart=${props.line.ID}`);
              }}
            >
              <span>Edit</span>
              <Icon
                path={exclamationCircle}
                class="w-5 h-5 inline ml-1 text-red-500 animate-pulse"
              />
            </button>
          </Show>
        </div>
      </div>
    </>
  );
};

type CJS = ChopLineItem | JoinLineItem | StackLineItem;
